@font-face {
    font-family: "Helvetica Now Display";
    src: local("Helvetica Now Display"),
        url(./static/fonts/woff/HelveticaNowDisplay.woff) format("woff");
}
@font-face {
    font-family: "Helvetica Now Display Bold";
    src: local("Helvetica Now Display Bold"),
        url(./static/fonts/woff/HelveticaNowDisplay-Bold.woff) format("woff");
}
@font-face {
    font-family: "Helvetica Now Display Italic";
    src: local("Helvetica Now Display Italic"),
        url(./static/fonts/woff/HelveticaNowDisplay-It.woff) format("woff");
}
@font-face {
    font-family: "Helvetica Now Display Light";
    src: local("Helvetica Now Display Light"),
        url(./static/fonts/woff/HelveticaNowDisplay-Light.woff) format("woff");
}
@font-face {
    font-family: "Helvetica Now Display Bold Italic";
    src: local("Helvetica Now Display Bold Italic"),
        url(./static/fonts/woff/HelveticaNowDisplay-BdIt.woff) format("woff");
}
@font-face {
    font-family: "Helvetica Now Display Light Italic";
    src: local("Helvetica Now Display Light Italic"),
        url(./static/fonts/woff/HelveticaNowDisplay-LtIt.woff) format("woff");
}

body {
    margin: 0;
    font-family: "Helvetica Now Display", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.mapContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.sidebarStyle {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(./static/icons/xmark-solid.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
}

/* TODO(hannah): Switch to a MUI DatePicker or find a better way to specify the
   text color so that it is theme-dependent. */
.react-daterange-picker input {
    color: #ffffff !important;
}
